*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    /* height: 100vh; */
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.title
{
    color:lightgray;
    font-size: xx-large;
    position: relative;
    margin-top: 4%;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.social-icon
{
    padding: 20px;
    text-align: center;
    text-decoration: none;
    width: 3vh;
    height: 3vh;
    filter: brightness(80%);
}

.social-icon:hover
{
    opacity: 0.7;
}